
import CardHorizontal from './CardHorizontal';

export default function NuestrosServicios(){


    const items = [
        { id: 1, folder: 'servicios', imagen: 'creditos.jpg', titulo: 'CREDITOS', contenido: 'Nuestros Asociados en aras de alcanzar sus expectativas personales o familiares, cuentan con unos microcréditos, los cuales les sirven para proyectar sus deseo de crear sus propias empresas, crear sus proyectos y mejorar sus ingresos, los cuales involucren el cambio de sus familias y su comunidad.' },
        { id: 2, folder: 'servicios', imagen: 'capacitaciones.jpg', titulo: 'CAPACITACIONES', contenido: 'La Cooperativa, tendrá la oportunidad de formar a sus asociados, Promoviendo y realizando actividades, campañas y capacitaciones en cada uno de los campos del sector Solidario a nivel nacional, municipal, regional y local, buscando en sus miembros el impulso del avance económico personal como de su comunidad, basados en un sistema que rige la economía solidaria en su comunidad.' },
        { id: 3, folder: 'servicios', imagen: 'eventos.jpg', titulo: 'EVENTOS SOCIALES', contenido: 'Como una Organización Social, esta entidad busca implementar políticas y medidas que fomente el sostenimiento ambiental, creando conciencia en los asociados que impacten su entorno.'},
        { id: 4, folder: 'servicios', imagen: 'deporte.jpg', titulo: 'RECREACION Y DEPORTES', contenido: 'como una manera de fomentar y Preservar valores, los recursos tangibles e intangibles así como los recursos humanos. Esta entidad busca Impulsar y crear una recreación que contribuya  a una vida sana, donde nuestra asociados se interesen por tener hábitos saludable, bajo líneas competitivas del deportes e incentivar a un aprovechamientos de los espacios que nos proporciona la Naturaleza en su amplia biodiversidad'},
        { id: 5, folder: 'servicios', imagen: 'aporte.jpg', titulo: 'APORTE SOCIAL', contenido: 'Esta entidad busca implementar un modelo económico que lleva intrínseco, un impulso personal que mueva un modelo comercial sostenible de sus asociados' },
        { id: 5, folder: 'servicios', imagen: 'adicional.jpg', titulo: 'ADICIONAL', contenido: 'Como una Entidad que busca el bien común, La Cooperativa esta dedicada a Organizar, gestionar una cultura ciudadana ayudando a Promover las políticas públicas de niñez, juventud, negritudes, mujeres, discapacidad entre otras, para la inclusión social y defensa de los derechos de estos, en lo económico, social, cultural y político, así como fomentar el desarrollo en beneficio de la población étnica, vulnerada en todo el territorio nacional.  También para desarrollo de proyectos para apoyar las estrategias para la identificación de niños, niñas y adolescentes los que se encuentren en situación de abandono o de riesgo,  abuso o maltrato infantil y atención a menores infractores' }
    ];


return(
    <div className="w-100 pt-2 text-center">
    <h3 className='p-3 fs-3'>
        Nuestros Servicios
    </h3>
    <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>
</div>
)


}