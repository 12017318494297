
import { slides } from '../img/slide20240524';
import Gallery from '../modules/Gallery';


export default function Eventos() {

    return (
        <div>
            <div className='mt-3'>
                <h3>Capacitaciones</h3>
                <p>Coopmulmag, cumpliendo su politica de capacitación continua. Brinda una socialización con la secretaria distrital de salud enfocada en Salud ocupacional y ambiente laboral.</p>
            </div>
            <Gallery slides={slides} />
        </div>
    )

}