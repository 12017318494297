import image_01 from './../img/20240524/01.jpg'
import image_02 from './../img/20240524/02.jpg'
import image_03 from './../img/20240524/03.jpg'


export const slides = [
    {
      src: image_01,
      title: '',
      description: '',
    },
    {
      src: image_02,
      title: '',
      description: '',
    },
    {
      src: image_03,
      title: '',
      description: '',
    },
  ]