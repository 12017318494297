export default function NosotrosContent() {

    return (
        <div className="w-75 mx-auto pt-5" style={{ textAlign: 'justify' }}>
            <h4><span ><strong>MISION</strong></span></h4>
            <p >La COOPERATIVA MULTIACTIVA DEL MAGDALENA Y DEL CARIBE, COOPMULMAG es una entidad sin ánimo de lucro que promueve la igualdad de oportunidades, la mejora de la calidad de vida y la integración social y laboral de sus asociados y de otros colectivos que presentan factores de apoyo y emprendimiento, ofreciendo una organización que se adapta a sus necesidades, con capacidad para trabajar y coordinarse con otras organizaciones y con un espíritu de investigación e innovación constantes.</p>
            <hr />
            <h4><span ><strong>VISION </strong></span></h4>
            <p>La COOPERATIVA MULTIACTIVA DEL MAGDALENA Y DEL CARIBE, COOPMULMAG quiere ser una entidad con un modelo de organización operativo, eficaz y participativo en el diseño y puesta en marcha de los proyectos que se propone llevar a cabo, y un punto de referencia para:</p>
            <p>
                <ul>
                    <li>Interés social, perseguir fines de beneficencia, el interés o utilidad común y el mejoramiento de la calidad de vida, el bienestar común y de interés social. Especialmente de servicios social a la comunidad. </li>
                    <li>Proyección social, tendrá como objeto, la promoción, impulsar o ejecutar proyecto de tipo social, cultural, ambiental, educativo, y económico, de carácter público o privado, con el fin de contribuir a la calidad de vida de los habitantes del territorio colombiano y en particular en los sectores menos favorecidos de la Ciudad de Santa Marta, o del territorio nacional.</li>
                    <li>Capital social, como fuente fiable de colocación y como oportunidad de promocionarse invirtiendo en beneficio de la comunidad en general. </li>
                    <li>Utilidad esperada, como modelo de gestión de proyectos de carácter social, y como referente estratégico y metodológico, Dar apoyo a la ejecución e implementación de proyectos y programas dirigidos a los menos favorecidos, buscando mejorar sus condiciones de vida, basados en los valores de justicia, libertad y reconciliación, en búsqueda de la paz en Colombia y el mundo. Auspiciar y promover estrategias de prevención y promoción alternativas para una vida saludable; y Promover y realizar actividades, campañas y capacitaciones en cada uno de los campos del sector de la seguridad social a nivel nacional, municipal, regional y local.  </li>

                </ul>
            </p>

            <h4><span ><strong>Valores </strong></span></h4>
            <p>
                <ul>
                    <li>Sin ánimo de lucro en el sentido de que la finalidad de la entidad no es la consecución de un beneficio económico y que en caso de excedente por la actividad desarrollada éste se reinvierte en beneficio social.</li>
                    <li>Laica porque se fundamenta en una estructura de valores internos que deben permitir la libertad individual para optar y actuar en cada una de las inflexiones importantes de la existencia humana, manteniendo la entidad independiente de cualquier religión o creencia, las cuales se han de desarrollar, si es necesario, en el ámbito privado y personal.</li>
                    <li>Defensora de los derechos humanos ya que son el exponente más claro de la dimensión social de la justicia y son aquellos que se fundamentan en la naturaleza humana como tal, anteriores y superiores a toda ley positiva, que son inalienables e inviolables, y que deben ser reconocidos y protegidos por los poderes públicos (representantes de la sociedad).</li>
                    <li>Apartidista en el sentido de que la entidad es independiente de cualquier opción política.</li>
                    <li>Tolerante, porque reconocemos que las diferencias entre las personas es un bien de la humanidad. Por lo tanto es necesario que la entidad actúe de manera abierta, con capacidad de aceptar las diferencias y saber convivir en la discrepancia, con capacidad de diálogo y transigencia sin encasillarse en las propias posiciones.</li>
                    <li>Solidaria, ya que dirigimos nuestra actividad a compensar las injusticias sociales en beneficio de las personas más vulnerables.</li>
                    <li>Humanista en el sentido de reconocer al ser humano como valor y preocupación central de su actividad, afirmando la igualdad de todas las personas, reconociendo la diversidad personal y cultural, priorizando el conocimiento por encima de lo que hoy está aceptado como verdad absoluta , afirmando la libertad de ideas y de creencias y rechazando todo tipo de violencia y de discriminación.</li>
                    <li>Creativa y con voluntad de mejora continua para dar respuestas a las necesidades que se van generando, de estimular la adaptación a los cambios y asumirlos, y de buscar de manera permanente soluciones de futuro posibles y sostenibles.</li>
                    <li>Reflexiva y crítica con la realidad educativa y social, fomentando el análisis e implicación en las diferentes situaciones socioeducativas que afectan a las personas de la entidad.</li>
                </ul>
            </p>

        </div>
    )
}