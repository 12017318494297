

import { GalleryEventos } from '../modules/GalleryEventos';
import Gallery from '../modules/Gallery';
import { slides } from '../img/slideEventos';

export default function Eventos() {

    return (
        <div className='container'>

            <div className='mt-3'>
                <h3>Celebracion fin de año</h3>
                <p>
                    Celebración de fin de año de en compañia de los miembros fundadores de la Cooperativa Multiactiva del Magdalena <strong>Coopmulmag</strong>.
                    Parte de la velada fue dedicada a una capacitación sobre lo que son las cooperativas, caracteristicas y funcionamiento.
                </p>
                <p>
                    Posterior a la capacitación, se realizó una cena y brindis de fin de año, esperando que vengan muchos años más llenos de exito.
                </p>
            </div>
            <Gallery slides={slides} ></Gallery>
        </div>
    )

}