import image_01 from './../img/20240526/01.jpg'
import image_02 from './../img/20240526/02.jpg'
import image_03 from './../img/20240526/03.jpg'
import image_04 from './../img/20240526/04.jpg'
import image_05 from './../img/20240526/05.jpg'
import image_06 from './../img/20240526/06.jpg'
import image_07 from './../img/20240526/07.jpg'


export const slides = [
    {
      src: image_01,
      title: '',
      description: '',
    },
    {
      src: image_02,
      title: '',
      description: '',
    },
    {
      src: image_03,
      title: '',
      description: '',
    },
    {
      src: image_04,
      title: '',
      description: '',
    },
    {
      src: image_05,
      title: '',
      description: '',
    },
    {
      src: image_06,
      title: '',
      description: '',
    },
    {
      src: image_07,
      title: '',
      description: '',
    },
  ]