

export default function FormularioContacto() {
    return (
        <div className="row bg-light">
            <div className='col-12 col-sm-6'>
                <img className="card-img-top" src={require('./../img/contacto.png')} alt="Card image cap" />
            </div>
            <div className='col-12 col-sm-6 p-4 '>
                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                <p><i className="fas fa-home me-3"></i>CL 14 27 195 CC Bastidas - Santa Marta</p>
                <p><i className="fas fa-envelope me-3"></i> coopmulmag@gmail.com</p>
                <p><i className="fas fa-phone me-3"></i>+57 315 6851124</p>
            </div>
        </div>
    )
}

interface formularioContactoProps {
    celular: string;
    correo: string;
    direccion: string;
}