import image_01 from './../img/eventos/01.jpg'
import image_02 from './../img/eventos/02.jpg'
import image_03 from './../img/eventos/03.jpg'
import image_04 from './../img/eventos/04.jpg'
import image_05 from './../img/eventos/05.jpg'
import image_06 from './../img/eventos/06.jpg'
import image_07 from './../img/eventos/07.jpg'
import image_08 from './../img/eventos/08.jpg'
import image_09 from './../img/eventos/09.jpg'



export const slides = [
    {
      src: image_01,
      title: 'Slide Title One',
      description: 'Slide Description One\n\nthis is new line',
    },
    {
      src: image_02,
      title: 'Slide Title Two',
      description: 'Slide Description Two',
    },
    {
      src: image_03,
      title: 'Slide Title Three',
      description: 'Slide Description Three',
    },
    {
      src: image_04,
      title: 'Slide Title Three',
      description: 'Slide Description Three',
    },
    {
      src: image_05,
      title: 'Slide Title Three',
      description: 'Slide Description Three',
    },
    {
      src: image_06,
      title: 'Slide Title Three',
      description: 'Slide Description Three',
    },
    {
      src: image_07,
      title: 'Slide Title Three',
      description: 'Slide Description Three',
    },
    {
      src: image_08,
      title: 'Slide Title Three',
      description: 'Slide Description Three',
    },
    {
      src: image_09,
      title: 'Slide Title Three',
      description: 'Slide Description Three',
    },
  ]