

import { CoordenadaDTO } from '../mapaLeaflet/Coordenada.model';
import Mapa from '../mapaLeaflet/Mapa';
import MapaFormulario from '../mapaLeaflet/MapaFormulario';
import FormularioContacto from '../modules/FormularioContacto';
import MapaGoogle from '../modules/MapaGoogle';
import SiderA from '../modules/SiderA';


export default function Contactenos() {
    const coordenadas: CoordenadaDTO[] = [{ lat: 11.240107530642291, lng: -74.18066214816368 }];
    
    return (
        <div className=' w-100'>
          <div className='row'>
                <div className="col-12 col-sm-9" >
                    <FormularioContacto />
                </div>
                <div className="col-12 col-sm-3" >
                    <SiderA />
                </div>
            </div>

            <div className="col-12" >
                <MapaFormulario campoLat='latitud' campoLng='longitud' coordenadas={coordenadas} />
            </div>
        </div>


    )

}