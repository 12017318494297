export default function Footer() {

  return (
    <div className=" mb-4">

      <footer className="text-center text-lg-start text-muted" >

       

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">

              <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3"></i>COOPMULMAG
                </h6>
                <p>
                  Cooperativa Multiactiva del Magdalena.
                </p>
              </div>




              <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mb-md-0 mb-4" style={{ textAlign: 'center' }} >

                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                <p><i className="fas fa-home me-3"></i>CL 14 27 195 CC Bastidas - Santa Marta</p>
                <p><i className="fas fa-envelope me-3"></i> coopmulmag@gmail.com</p>
                <p><i className="fas fa-phone me-3"></i>+57 315 6851124</p>
              </div>

            </div>

          </div>
        </section>



        <div className="text-center pt-4 pb-4 ">
          <p>
            © 2022 Copyright: <a style={{ textDecoration: 'none' }} className="text-reset fw-bold" href="https://multinet.com.co/">  Multinet Soluciones Digitales</a>
          </p>
        </div>
      </footer>
    </div>


  )


}