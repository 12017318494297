

import { slides } from '../img/slide202312';
import Gallery from '../modules/Gallery';

export default function Social() {

    return (
        <div>   
            <div className='mt-3'>
                <h3>Compromiso social</h3>
                <p>Coopmulmag. Está comprometida con la labor social, nuestros cooperados fueron premiados con obsequios y anchetas para celebrar la navidad 2023.</p>
            </div>
            <Gallery slides={slides} />
        </div>
    )

}