import image_01 from './../img/social/01.jpg'
import image_02 from './../img/social/02.jpg'
import image_03 from './../img/social/03.jpg'
import image_04 from './../img/social/04.jpg'
import image_05 from './../img/social/05.jpg'
import image_06 from './../img/social/06.jpg'


export const slides = [
    {
      src: image_01,
      title: '',
      description: '',
    },
    {
      src: image_02,
      title: '',
      description: '',
    },
    {
      src: image_03,
      title: '',
      description: '',
    },
    {
      src: image_04,
      title: '',
      description: '',
    },
    {
      src: image_05,
      title: '',
      description: '',
    },
    {
      src: image_06,
      title: '',
      description: '',
    },
  ]