

export default function ImagenCentral() {
    return (

        <div className="col-12 col-sm-8 mx-auto m-4">
                <img width="100%" src={require("../img/banner.jpg")}></img>
        </div>       

    )
}


