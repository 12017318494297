import Logo from './../img/logosmallblanco.png'
import { Link, NavLink } from 'react-router-dom';
import { Background } from 'react-parallax';

export default function Menu() {

  const claseActiva = "active";

  return (
    <div className=''>



      <nav className="navbar navbar-expand-lg navbar-light static-top" style={{ marginTop: '10px' }} >
        <div className="container">



          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>



          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{ fontSize: '18px', fontWeight: '500' }} >

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/"   >Inicio</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/nosotros"  >Nosotros</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/servicios"  >Servicios</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/contactenos"  >Contactenos</NavLink>
              </li>



              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/eventos"  >Eventos</NavLink>
              </li>



              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/capacitaciones"  >Capacitaciones</NavLink>
              </li>


              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/social"  >Social</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link "} to="/deporte"  >Deporte</NavLink>
              </li>


            </ul>
          </div>


        </div>
      </nav>
    </div>


  )

}