

import NosotrosContent from '../modules/NosotrosContenido';
export default function Nosotros() {

    return (
        <div>
            <NosotrosContent></NosotrosContent>
        </div>
    )

}