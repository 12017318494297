import Logo from './../img/logo.png'



export default function SiderA(){
    return(
        <div  className="me-12 d- mt-4 text-center"  >
          <h4>COOPMULMAG</h4>
          <p>
          Cooperativa Multiactiva del Magdalena
          </p>
          
          <p >
          <img className='d-block w-100' src={Logo} alt='Mensajero' />    
          </p>
          
        </div>
        
    )
}