export default function DescripcionSitio() {
    return (
        <section className="pt-2">
            <div className="container-fluid pt-10 text-center">
                <h4>COOPMULMAG</h4>
                <p>
                    Cooperativa Multiactiva del Magdalena.
                </p>
            </div>
        </section>
    )
}