
import { slides } from '../img/slide20240526';
import Gallery from '../modules/Gallery';


export default function Deporte() {

    return (
        <div>
            <div className='mt-4'>
                <h3>Comprometidos con el deporte</h3>
                <p>En Coopmulmag, estamos comprometidos con el desarrollo integral de nuestra comunidad y creemos firmemente en el poder del deporte para formar valores y promover un estilo de vida saludable entre los más jóvenes.</p>
                <p>Creemos que apoyar al deporte infantil es invertir en el futuro de nuestra comunidad. Nos comprometemos a seguir promoviendo iniciativas que fomenten la educación, el desarrollo personal y el bienestar de los niños y jóvenes.</p>
            </div>
            <Gallery slides={slides}/>
        </div>
    )

}